import { CommonMargin } from 'components/_layout/CommonMargin';
import SEO from 'components/_layout/Seo';
import React from 'react';
import { RelativeSection } from 'sections/Offer/Offer.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import styled from 'styled-components';
import { BenefitsSection } from 'sections/DeepTech/SoundAndVoiceAiProcessing/components/BenefitsSection';
import { ImageWithTextSection } from 'sections/DeepTech/SoundAndVoiceAiProcessing/components/ImageWithTextSection';
import { ChooseCodaheadSection } from 'sections/DeepTech/SoundAndVoiceAiProcessing/components/ChooseCodaheadSection';
import { DistinctSection } from 'sections/DeepTech/SoundAndVoiceAiProcessing/components/DistinctSection';
import { OurOfferSection } from 'sections/_universal/OurOfferSection';
import { TakeThisStepSection } from 'sections/_universal/TakeThisStepSection';

export default () => {
  const isMd = useBreakpoint('md');

  return (
    <>
      <SEO
        title="Voice AI Solutions – Cutting-Edge Voice AI Services"
        meta={[
          {
            name: 'keywords',
            content:
              'voice ai solutions, ai voice solutions, ai voice services, voice AI processing, ai voice services',
          },
        ]}
        description={
          'Codahead delivers AI voice solutions for businesses looking to optimize communication with cutting-edge technology. Learn more today!'
        }
      />

      <CommonMargin>
        <StyledSection
          title="Sound and Voice AI processing"
          variant="secondary"
          id="Sound and Voice AI processing"
          isMobile={isMd}
          withBreadCrumbs
        >
          <HeaderSection>
            In a world where sound is the next frontier of innovation, our Sound and Voice AI Processing solutions
            empower businesses to create smarter, more intuitive applications. From speech recognition to acoustic
            analysis, we deliver state-of-the-art AI-powered solutions that enhance user experiences, improve
            efficiency, and open new opportunities. Whether you're building voice assistants, audio analytics platforms,
            or sound-enabled IoT devices, we transform your vision into reality.
          </HeaderSection>
        </StyledSection>

        <DistinctSection />
        <ImageWithTextSection />
        <ChooseCodaheadSection />
        <BenefitsSection />
        <OurOfferSection />
        <TakeThisStepSection />
      </CommonMargin>
    </>
  );
};

const HeaderSection = styled.div`
  margin-top: 40px;
  max-width: 900px;
  font-size: 20px;
  line-height: 1.65;
  font-weight: 300;
`;

const StyledSection = styled(RelativeSection)`
  margin-top: 24px;
`;
